:root {
    --color-primary: #2584ff;
    --color-secondary: #00d9ff;
    --color-accent: #ff3400;
    --color-headings: #1b0760;
    --color-body: #918ca4;
    --color-body-darker: #5c5577;
    --color-border: #ccc;
    --border-radius: 30px;
  }

  *, *::after, *::before {
    box-sizing: border-box;
  }
  
  /* Typography */
  
  ::selection {
    background: var(--color-primary);
    color: #fff;
  }
  
  html {
    font-size: 62.5%;
  }
  
  img {
    width: 100%;
  }

body {
    font-family: Inter, Arial, Helvetica, sans-serif;
    font-size: 2rem;  
    line-height: 1.5;
    color: var(--color-body);
  }  
  h1, h2, h3 {
    color: var(--color-headings);
    margin-bottom: 1rem;
    line-height: 1.1;
  }
  
  h1 {
    font-size: 6rem;
  }
  
  h2 {
    font-size: 4rem;
  }
  
  h3 {
    font-size: 2.8rem;
    font-weight: 600;
    line-height: 1.3;
  }
  
  p {
    margin-top: 0;
  }

@media screen and (min-width: 1024px) {
    body {
      font-size: 1.8rem;
    }
    h1 {
      font-size: 8rem;
    }
    h2 {
      font-size: 4rem;
    }
    h3 {
      font-size: 2.4rem;
    }
  }


@media only screen and (max-width: 768px) {
    .aos-animate {
      animation: none !important;
      transition: none !important;
      transform: none !important;
      /* -o-transition-property: none !important;
      -moz-transition-property: none !important;
      -ms-transition-property: none !important;
      -webkit-transition-property: none !important;
      transition-property: none !important;
  
      -o-transform: none !important;
      -moz-transform: none !important;
      -ms-transform: none !important;
      -webkit-transform: none !important;
      transform: none !important;
  
      -webkit-animation: none !important;
      -moz-animation: none !important;
      -o-animation: none !important;
      -ms-animation: none !important;
      animation: none !important; */
    }
  }
      