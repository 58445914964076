.loader-container {
  width: 100%;
  height: 100%;
  background-color: white;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
}

.loader-container.loading {
  opacity: 1;
  visibility: visible;
}

.loading-icon {
  /* Remove font-size and font-weight properties */
  /* font-size: 20rem;
  font-weight: bold; */
  color: #eae6e6;
}

.loading-icon.zoom {
  animation: zoomOut 4s ease-in-out forwards;
  animation-delay: 2s;
}

.svg-icon {
  height: 100vh;
  left: 0;
  object-fit: fill;
  position: fixed;
  top: 0;
  width: 100%
}

@keyframes zoomOut {
  0% {
    transform: scale(3);
  }

  100% {
    transform: scale(800);
  }
}