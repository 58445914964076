.wrapper {
    margin: 0;
    display: grid;
    place-items: center;
    height: 100svh;
    height: 100vh;
    background: #000814;
}

.gallery_wrapper {
    --img-size: calc(100px - 0.5rem / 2);
    display: grid;
    gap: 0.5rem;
    grid-template-columns: repeat(6, var(--img-size));
    grid-template-rows: repeat(4, var(--img-size));
}

.img {
    max-width: 100%;
    aspect-ratio: 1;
    object-fit: cover;
    grid-column: span 2;
    border-radius: 0.5rem;
    clip-path: path("M 80 20 C 100 0 100 0 120 20 C 140 40 160 60 180 80 C 200 100 200 100 180 120 C 160 140 140 160 120 180 C 100 200 100 200 80 180 C 60 160 40 140 20 120 C 0 100 0 100 20 80 Z"
        );
    transition: filter 500ms, clip-path 500ms;
}

.img:nth-of-type(4),
.img:nth-of-type(9) {
    grid-column: 2 / span 2;
}

.img:hover {
    z-index: 100;
    clip-path: path("M 0 0 C 100 0 100 0 200 0 C 200 50 200 50 200 80 C 200 100 200 100 200 120 C 200 150 200 150 200 200 C 100 200 100 200 0 200 C 0 150 0 150 0 120 C 0 100 0 100 0 80 Z"
        );
}

.img:not(:hover) {
    animation: zIndexFix 500ms;
}

.gallery_wrapper:hover>img {
    filter: brightness(0.5) saturate(0.5);
}

.gallery_wrapper>img:hover {
    filter: brightness(1) saturate(1.5);
}

@keyframes zIndexFix {

    0%,
    100% {
        z-index: 10;
    }
}