.heropg {
    background-color: #eae6e6;
    background-image: url("../Assests/Images/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    /* background-color: #d9d9d9; */
    position: relative;
    /* top: 5vh; */
    color: black;
    display: flex;
    flex-direction: row;
    padding-top: 5vh;
    justify-content: space-evenly;
    align-items: center;
    width: 100vw;
    z-index: 15;
    height: 100vh;
    /* overflow: hidden; */
    padding: 3rem;
    gap: 3rem;
    /* padding: 0 4rem 0 4rem; */
}

.hero-video {
    /* background-color: #fad648; */
    border-radius: 25px;
    /* padding-bottom: 0; */
    padding: 0;
    /* width: 40vw; */
    z-index: 0;
    /* flex:1; */
    min-width: 30vw;
}


.hero-video video {
    /* height: 90vh; */
    max-height: 80vh;
    /* height: 80vh; */
    /* width: 30vw; */
    border-radius: 40px;
    object-fit: cover;
    min-width: 30vw;
}

.hero-text {
    z-index: 2;
    /* flex-grow: 1; */
    /* flex:1; */
    text-align: right;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: right;
    gap: 1rem;
    /* padding: 5rem; */
    /* float: left; */
    color: rgb(249, 239, 219),
}

.mainliner {
    font-size: 14vh;
    /* font-size: 118px; */
    line-height: 15vh;

    /* opacity: 0; */
    /* display: flex; */
    /* flex-direction: column; */
}

.mainliner .line4 {
    font-size: 3vh;
    line-height: 4rem;
}

.subliner {
    color: rgba(249, 239, 219);
    font-size: 1.5rem;
    line-height: 1.8rem;
    white-space: normal;
}

.tedxlogo img {
    width: 20vw;
    position: relative;
    right: -1vw;
}

.redtext {
    color: #eb0028;
}

.bold {
    font-weight: 700;
}

.svg-container {
    position: absolute;

}

.lineimg {
    position: absolute;
    left: 10vw;
    top: -15vh;
    z-index: 1;
}

.lineimg img {
    width: 90vw;
    height: 150vh;
    transform: rotate(351deg);
    overflow: hidden;
}

.svglines svg {
    /* position: absolute; */
    /* height: 70vh; */
    width: 50vw;
    z-index: 1;
    width: 70vw;
    z-index: 1;
    left: 20vw;
    top: -10vw;
}

@media screen and (max-width: 1025px) {
    .mainliner {
        font-size: 5rem;
        line-height: 5.3rem;
    }

    .mainliner .line4 {
        font-size: 2vh;
        line-height: 4rem;
    }

    .hero-video video {
        /* height:auto; */
        min-height: 50vh;
        max-width: 40vw;
    }
}

@media screen and (max-width: 1000px) {
    .mainliner {
        font-size: 4rem;
        line-height: 5.3rem;
    }

    .mainliner .line4 {
        font-size: 2vh;
        line-height: 4rem;
    }

    .hero-video video {
        /* height:auto; */
        min-height: 50vh;
        min-width: 30vw;
    }
}

@media screen and (max-width: 750px) {
    .mainliner .line4 {
        font-size: 2vh;
        line-height: 3rem;
    }

    .heropg {
        flex-direction: column-reverse;
        min-height: 100vh;
        height: auto;
        justify-content: flex-end;
        /* padding: 1rem; */
        padding: 3rem 0rem;
    }

    .hero-text {
        z-index: 2;
        width: 85vw;
        /* flex-grow: 1; */
        text-align: left;
        align-items: flex-end;
        /* padding: 1rem; */
        gap: 0.5rem;
        /* float: left; */
    }

    .mainliner {
        font-size: 3.7rem;
        line-height: 3.4rem;
        text-align: left;
        align-self: flex-start;
    }

    .subliner {
        font-size: 1.6rem;
        line-height: 1.7rem;
        text-align: right;
        white-space: normal;
        /* align-self: flex-end; */
    }

    /* .texlogo {
        align-self: flex-end;
        right: -1vw;
    } */
    .lineimg {
        position: absolute;
        left: -58vw;
        top: -12vh;
        z-index: 1;

    }

    .lineimg img {
        width: 175vw;
        height: 87vh;
        transform: rotate(90deg) rotate(313deg);
    }

    .tedxlogo img {
        width: 60vw;
        position: relative;
        right: -3vw;
    }

    .flower {
        position: relative;
        height: 40vh;
        align-self: flex-start;
    }

    .herowhiteflower img,
    .heroredflower img,
    .lineimg img {
        max-width: none;
    }

    .heroredflower,
    .heroredflower {
        z-index: 100;
    }

    .herowhiteflower img {
        position: relative;
        width: 27vh;
        z-index: 100;
        /* left: 33vw; */
        right: -32vw;
        /* top: -5vh;*/
    }

    .heroredflower img {
        width: 24vh;
        position: relative;
        z-index: 100;
        top: -11vh;
        left: -7vw;

    }

    .heroyellow2 hr {
        position: relative;
        /* transform: translate(39vw, 10vh); */
        width: 95vw;
        height: 26vh;
        background-color: #fad648;
        border-radius: 5vh 5vh 5vh 5vh;
        border-color: #fad648;
        z-index: -1;
        left: -5vw;
        top: -42vh;
    }
}