.hey {
  text-align: center;
  overflow: hidden;
  background: rgb(19, 19, 19);
}

.fixed-headings {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 15vh;
  width: 400px;
  left: 50%;
  transform: translateX(-50%);
}


.App {
  text-align: center;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'SWISS721BLACKBT';
  src: local('SWISS721BLACKBT'), url(./Components/Assests/Images/SWISS721BLACKBT.TTF) format('truetype');
  src: local('SWISS721BLACKBT'), url(./Components/Assests/Images/Swiss721BT-Black.eot) format('embedded-opentype');
  src: local('SWISS721BLACKBT'), url(./Components/Assests/Images/Swiss721BT-Black.woff2)format("woff2");
  src: local('SWISS721BLACKBT'), url(./Components/Assests/Images/Swiss721BT-Black.woff)format("woff");
  src: local('SWISS721BLACKBT'), url(./Components/Assests/Images/SWISS721BLACKBT.TTF)format("truetype");
}