:root {
    --duration: 20s;
    --delay: 20s;
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.image-slider {
    position: relative;
    background-color: #141414;
    margin: 0 auto;
    padding: 0 1rem;
    height: 100vh;
    overflow: hidden;
    transform-style: preserve-3d;
    perspective: 25rem;
}

.slide {
    --duration: 40s;
    --name: float-up-1;
    position: absolute;
    top: 0;
    left: 0;
    width: 33vw;
    transform: translateY(200vh);
    animation: var(--name) var(--duration) linear infinite;
    box-shadow: 1px 3px 15px rgba(0, 0, 0, 0.5);
    z-index: 1;
}

/* Define the pattern */
@keyframes float-up-1 {
    from {
        transform: translateY(100vh);
    }

    to {
        transform: translateY(-100vh);
    }
}

@keyframes float-up-2 {
    from {
        transform: translateY(150vh) translateZ(-25vh);
    }

    to {
        transform: translateY(-150vh) translateZ(-25vh);
    }
}

@keyframes float-up-3 {
    from {
        transform: translateY(200vh) translateZ(-50vh);
    }

    to {
        transform: translateY(-200vh) translateZ(-50vh);
    }
}

/* Slides 1 - 30 with different parameters */
.slide:nth-child(1) {
    animation-delay: -3s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 0vw;
    z-index: -1;
}

.slide:nth-child(2) {
    animation-delay: -6s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 10vw;
    z-index: -3;
}

.slide:nth-child(3) {
    animation-delay: -9s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 90vw;
    z-index: -2;
}

.slide:nth-child(4) {
    animation-delay: -12s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 36vw;
    z-index: -1;
}

.slide:nth-child(5) {
    animation-delay: -15s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 62vw;
    z-index: -3;
}

.slide:nth-child(6) {
    animation-delay: -18s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 15vw;
    z-index: -2;
}

.slide:nth-child(7) {
    animation-delay: -21s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 55vw;
    z-index: -2;
}

.slide:nth-child(8) {
    animation-delay: -24s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: -20vw;
    z-index: -3;
}

.slide:nth-child(9) {
    animation-delay: -27s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 68vw;
    z-index: -1;
}

.slide:nth-child(10) {
    animation-delay: -30s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 0vw;
    z-index: -2;
}

.slide:nth-child(11) {
    animation-delay: -33s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 30vw;
    z-index: -1;
}

.slide:nth-child(12) {
    animation-delay: -36s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 40vw;
    z-index: -3;
}

.slide:nth-child(13) {
    animation-delay: -39s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 80vw;
    z-index: -2;
}

.slide:nth-child(14) {
    animation-delay: -42s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 25vw;
    z-index: -1;
}

.slide:nth-child(15) {
    animation-delay: -45s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 50vw;
    z-index: -3;
}

.slide:nth-child(16) {
    animation-delay: -48s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 75vw;
    z-index: -2;
}

.slide:nth-child(17) {
    animation-delay: -51s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 5vw;
    z-index: -1;
}

.slide:nth-child(18) {
    animation-delay: -54s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 45vw;
    z-index: -3;
}

.slide:nth-child(19) {
    animation-delay: -57s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 85vw;
    z-index: -2;
}

.slide:nth-child(20) {
    animation-delay: -60s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 20vw;
    z-index: -1;
}

.slide:nth-child(21) {
    animation-delay: -63s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 60vw;
    z-index: -3;
}

.slide:nth-child(22) {
    animation-delay: -66s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 0vw;
    z-index: -2;
}

.slide:nth-child(23) {
    animation-delay: -69s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 40vw;
    z-index: -1;
}

.slide:nth-child(24) {
    animation-delay: -72s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 80vw;
    z-index: -3;
}

.slide:nth-child(25) {
    animation-delay: -75s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 10vw;
    z-index: -2;
}

.slide:nth-child(26) {
    animation-delay: -78s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 50vw;
    z-index: -1;
}

.slide:nth-child(27) {
    animation-delay: -81s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 90vw;
    z-index: -3;
}

.slide:nth-child(28) {
    animation-delay: -84s;
    --name: float-up-2;
    --duration: calc(20s * 2);
    left: 30vw;
    z-index: -2;
}

.slide:nth-child(29) {
    animation-delay: -87s;
    --name: float-up-1;
    --duration: calc(20s * 1);
    left: 70vw;
    z-index: -1;
}

.slide:nth-child(30) {
    animation-delay: -90s;
    --name: float-up-3;
    --duration: calc(20s * 3);
    left: 15vw;
    z-index: -3;
}